import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./Home"; // A component to display the image
import FindAvatar from "./FindAvatar";
import UserImage from "./UserImage";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/find-your-avatar" element={<FindAvatar />} />
        <Route path="/user" element={<UserImage />} />
        {/* <Route path="*" element={<Navigate to="/" />} /> */}
      </Routes>
    </Router>
  );
}

export default App;
