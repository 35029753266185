import React, { useState, useEffect } from "react";
import { ref, listAll, getDownloadURL } from "firebase/storage";
import { NavLink, useNavigate } from "react-router-dom";
import { Scanner } from "@yudiel/react-qr-scanner";
import { storage } from "./firebase"; // Firebase storage initialized
import { HeaderImage } from "./header.js";

const FindAvatar = () => {
  const navigate = useNavigate();
  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <div style={{ marginTop: "10px", marginBottom: "10px" }}>
        <HeaderImage />
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
          marginBottom: "10px",
          border: "1px solid black",
          boxSizing: "border-box",
        }}
      >
        <NavLink
          to="/"
          style={({ isActive, isPending }) => {
            return {
              textAlign: "center",
              width: "50%",
              color: isActive ? "white" : "black",
              textDecoration: "none",
              padding: "5px",
              backgroundColor: isActive ? "black" : "inherit",
            };
          }}
          className={({ isActive, isPending }) => {
            return isActive ? "active" : isPending ? "pending" : "";
          }}
        >
          Image Feed
        </NavLink>
        <NavLink
          to="/find-your-avatar"
          style={({ isActive, isPending }) => {
            return {
              textAlign: "center",
              color: isActive ? "white" : "black",
              width: "50%",
              textDecoration: "none",
              padding: "5px",
              backgroundColor: isActive ? "black" : "inherit",
            };
          }}
          className={({ isActive, isPending }) => {
            return isActive ? "active" : isPending ? "pending" : "";
          }}
        >
          Selfie Finder
        </NavLink>
      </div>
      <div style={{ width: "100%" }}>
        <Scanner
          onScan={(result) => {
            console.log("Scanned QR code with result: ", result);
            navigate(`/user?searchString=${result[0].rawValue}`);
          }}
          components={{ finder: false }}
        />
      </div>
      <p
        style={{
          margin: "0 auto",
          fontSize: "22px",
          textAlign: "center",
          fontWeight: 600,
          marginTop: "12px",
          color: "gray",
        }}
      >
        To find your digital mirror selfie, please scan the QR code on your
        CineKid ticket!
      </p>
    </div>
  );
};

const styles = {
  feed: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "10px",
  },
  post: {
    width: "500px",
    border: "1px solid black",
    marginBottom: "20px",
    backgroundColor: "#fff",
  },
  header: {
    display: "flex",
    alignItems: "center",
    padding: "10px",
  },
  profilePic: {
    width: "40px",
    height: "40px",
    borderRadius: "50%",
    marginRight: "10px",
  },
  username: {
    fontWeight: "bold",
  },
  postImage: {
    width: "100%",
    height: "auto",
  },
  caption: {
    padding: "10px",
  },
};

export default FindAvatar;
