import React, { useState, useEffect } from "react";
import { ref, listAll, getDownloadURL } from "firebase/storage";
import { NavLink } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import { storage } from "./firebase"; // Firebase storage initialized
import { HeaderImage } from "./header.js";
import { compliments, usernames } from "./constants.js";

const FancyMirror = ({ imageUrl, mirrorFrame }) => {
  return (
    <div className="mirror-frame">
      <div className="mirror-glass">
        <img src={imageUrl} alt="Reflected content" className="mirror-image" />
      </div>
    </div>
  );
};

const Post = ({ profilePic, username, imageUrl, caption }) => {
  return (
    <div style={styles.post}>
      <div style={styles.header}>
        <img
          src={imageUrl}
          alt={`Big dummy's profile`}
          style={styles.profilePic}
        />
        <span style={styles.username}>{username}</span>
      </div>
      <div style={{ display: "flex", width: "100%", justifyContent: "center" }}>
        <img src={imageUrl} alt="Post" style={styles.postImage} />
        {/* <FancyMirror imageUrl={imageUrl} /> */}
      </div>

      <div style={styles.caption}>
        <span style={{ fontWeight: "700" }}>{username + " "}</span>{" "}
        <span style={{ fontWeight: "500" }}>
          {compliments[Math.floor(Math.random() * (compliments.length - 1))]}
        </span>{" "}
      </div>
    </div>
  );
};

const Home = () => {
  const [images, setImages] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [nextPageToken, setNextPageToken] = useState(null);

  const storageRef = ref(storage); // Adjust path to your folder

  const fetchImages = async () => {
    try {
      const options = nextPageToken ? { pageToken: nextPageToken } : {};
      const response = await listAll(storageRef, options);
      const imageUrls = await Promise.all(
        response.items.map((item) => getDownloadURL(item))
      );

      const newImages = imageUrls.filter((url) => !images.includes(url));

      setImages((prevImages) => [...newImages]);
      setNextPageToken(response.nextPageToken || null);
      setHasMore(!!response.nextPageToken);
    } catch (error) {
      console.error("Error fetching images:", error.message);
    }
  };

  useEffect(() => {
    fetchImages();
  }, []);

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div style={{ marginTop: "10px", marginBottom: "10px" }}>
        <HeaderImage />
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
          marginBottom: "10px",
          border: "1px solid black",
          boxSizing: "border-box",
        }}
      >
        <NavLink
          to="/"
          style={({ isActive, isPending }) => {
            return {
              textAlign: "center",
              width: "50%",
              color: isActive ? "white" : "black",
              textDecoration: "none",
              padding: "5px",
              backgroundColor: isActive ? "black" : "inherit",
            };
          }}
          className={({ isActive, isPending }) => {
            return isActive ? "active" : isPending ? "pending" : "";
          }}
        >
          Image Feed
        </NavLink>
        <NavLink
          to="/find-your-avatar"
          style={({ isActive, isPending }) => {
            return {
              textAlign: "center",
              color: isActive ? "white" : "black",
              width: "50%",
              textDecoration: "none",
              padding: "5px",
              backgroundColor: isActive ? "black" : "inherit",
            };
          }}
          className={({ isActive, isPending }) => {
            return isActive ? "active" : isPending ? "pending" : "";
          }}
        >
          Selfie Finder
        </NavLink>
      </div>
      {/* <InfiniteScroll dataLength={images.length}
        next={fetchImages}
        hasMore={hasMore}
        loader={<h4>Loading more images...</h4>}
        endMessage={<p style={{ margin: "0 auto" }}>No more images</p>}
      > */}
      {images.map((image, index) => (
        <Post
          key={index}
          profilePic={image}
          username={
            usernames[Math.floor(Math.random() * (usernames.length - 1))]
          }
          imageUrl={image}
          caption={Date.now()}
        />
      ))}
      {/* </InfiniteScroll> */}
    </div>
  );
};

const styles = {
  feed: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    padding: "10px",
  },
  post: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    border: "1px solid black",
    marginBottom: "20px",
    backgroundColor: "#fff",
  },
  header: {
    display: "flex",
    alignItems: "center",
    padding: "10px",
  },
  profilePic: {
    width: "40px",
    height: "40px",
    borderRadius: "50%",
    marginRight: "10px",
  },
  username: {
    fontWeight: "bold",
  },
  postImage: {
    width: "100%",
    height: "auto",
  },
  caption: {
    padding: "10px",
  },
};

export default Home;
