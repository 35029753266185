import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyAWrFgu4LLN667k8uyDbDS_1f6bOZjQA58",
  authDomain: "the-closet-f4da4.firebaseapp.com",
  projectId: "the-closet-f4da4",
  storageBucket: "the-closet-f4da4.appspot.com",
  messagingSenderId: "227602646633",
  appId: "1:227602646633:web:b96f6cc964c3dad686c4f8",
};
const app = initializeApp(firebaseConfig);
const storage = getStorage(app);

export { storage };
