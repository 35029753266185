export const usernames = [
  "HappyPanda",
  "BraveLion",
  "CleverFox",
  "GentleDolphin",
  "SwiftEagle",
  "CalmTurtle",
  "WiseOwl",
  "PlayfulKitten",
  "LoyalDog",
  "MightyElephant",
  "FriendlyRabbit",
  "GracefulSwan",
  "CheerfulParrot",
  "BoldTiger",
  "QuietDeer",
  "JollyPenguin",
  "NobleHorse",
  "ShyKoala",
  "CuriousMonkey",
  "ProudPeacock",
  "SillyGoose",
  "KindSheep",
  "StrongBear",
  "BrightButterfly",
  "CharmingSparrow",
  "DaringHawk",
  "EagerBeaver",
  "FiercePanther",
  "GentleLamb",
  "HappySeal",
  "InquisitiveRaccoon",
  "JoyfulDolphin",
  "KeenFalcon",
  "LivelyCricket",
  "MerryDuck",
  "NimbleSquirrel",
  "ObservantHedgehog",
  "PeacefulDove",
  "QuickCheetah",
  "RadiantFirefly",
  "SincereOtter",
  "ThoughtfulBadger",
  "UpbeatKangaroo",
  "VibrantGoldfish",
  "WittyFerret",
  "ZestyZebra",
  "AdventurousLynx",
  "BubblyHamster",
  "CaringWhale",
  "DiligentAnt",
  "EnergeticFrog",
  "FaithfulDonkey",
  "GenerousGiraffe",
  "HonestBuffalo",
  "InventiveOctopus",
  "JovialSeal",
  "KindheartedPony",
  "LuminousFirefly",
  "ModestMole",
  "NurturingHen",
  "OptimisticLark",
  "PatientCamel",
  "QuickWombat",
  "ReliableOx",
  "SensibleGoat",
  "TrustworthyBison",
  "UnderstandingLemur",
  "ValiantStag",
  "WarmheartedHusky",
  "YouthfulFawn",
  "ZealousMongoose",
  "AffectionatePuppy",
  "BlissfulFinch",
  "ConsideratePorcupine",
  "DeterminedJaguar",
  "EnthusiasticSeal",
  "FearlessWolf",
  "GraciousGazelle",
  "HumbleCrane",
  "ImaginativePlatypus",
  "JubilantRobin",
  "KeenEagle",
  "LoyalHound",
  "MindfulHeron",
  "NobleStork",
  "ObservantOcelot",
  "PatientTortoise",
  "QuickRabbit",
  "ResourcefulBeetle",
  "SincereLynx",
  "ThoughtfulMarmot",
  "UpbeatParakeet",
  "VigorousFalcon",
  "WittyWeasel",
  "ZanyZebu",
  "AdventurousOtter",
  "BenevolentBat",
  "CourageousCougar",
  "DiligentDingo",
  "EagerEagle",
  "FaithfulFerret",
  "GenerousGull",
  "HonestHawk",
];

export const compliments = [
  "Looking great!",
  "What a look!",
  "You dress well!",
  "Fantastic outfit!",
  "You look amazing!",
  "Stylish as always!",
  "You're rocking that outfit!",
  "Absolutely stunning!",
  "You have great style!",
  "Fashionable and fabulous!",
  "You look sharp!",
  "Dressed to impress!",
  "You look dapper!",
  "Chic and elegant!",
  "You look marvelous!",
  "You're a fashion icon!",
  "You look splendid!",
  "You're looking fabulous!",
  "You have a great sense of style!",
  "You look so stylish!",
  "You're looking sharp!",
  "You look fantastic!",
  "You're dressed to the nines!",
  "You look so fashionable!",
  "You're looking dashing!",
  "You look so chic!",
  "You're looking elegant!",
  "You look so put together!",
  "You're looking classy!",
  "You look so polished!",
  "You're looking sophisticated!",
  "You look so trendy!",
  "You're looking so fresh!",
  "You look so cool!",
  "You're looking so neat!",
  "You look so smart!",
  "You're looking so sleek!",
  "You look so refined!",
  "You're looking so posh!",
  "You look so debonair!",
  "You're looking so suave!",
  "You look so snazzy!",
  "You're looking so spiffy!",
  "You look so natty!",
  "You're looking so dapper!",
  "You look so spruce!",
  "You're looking so crisp!",
  "You look so neat!",
  "You're looking so tidy!",
  "You look so immaculate!",
  "You're looking so pristine!",
  "You look so polished!",
  "You're looking so elegant!",
  "You look so graceful!",
  "You're looking so poised!",
  "You look so dignified!",
  "You're looking so stately!",
  "You look so majestic!",
  "You're looking so grand!",
  "You look so noble!",
  "You're looking so regal!",
  "You look so splendid!",
  "You're looking so magnificent!",
  "You look so glorious!",
  "You're looking so radiant!",
  "You look so dazzling!",
  "You're looking so brilliant!",
  "You look so luminous!",
  "You're looking so vibrant!",
  "You look so vivid!",
  "You're looking so bright!",
  "You look so colorful!",
  "You're looking so lively!",
  "You look so spirited!",
  "You're looking so animated!",
  "You look so energetic!",
  "You're looking so dynamic!",
  "You look so vivacious!",
  "You're looking so effervescent!",
  "You look so bubbly!",
  "You're looking so sparkling!",
  "You look so glittering!",
  "You're looking so shimmering!",
  "You look so radiant!",
  "You're looking so glowing!",
  "You look so beaming!",
  "You're looking so shining!",
  "You look so gleaming!",
  "You're looking so lustrous!",
  "You look so glossy!",
  "You're looking so polished!",
  "You look so sleek!",
  "You're looking so smooth!",
  "You look so silky!",
  "You're looking so velvety!",
  "You look so soft!",
  "You're looking so plush!",
  "You look so luxurious!",
  "You're looking so opulent!",
  "You look so rich!",
  "You're looking so sumptuous!",
  "You look so lavish!",
  "You're looking so extravagant!",
  "You look so splendid!",
  "You're looking so magnificent!",
];
